
import { defineComponent, ref } from "vue";

import Swal from "sweetalert2";
import axios from "axios";


export default defineComponent({
  name: "add-Forma_pagamento-modal",
  props : ['forma_pagamento'],
  data() {
    return {
    
    }
  },
  mounted() {
	   this.$nextTick(function () {
			
	})

  },

  methods:{

    
	salvarForma_pagamento(){

    if(this.forma_pagamento.id){

      axios.put(process.env.VUE_APP_API_URL + '/forma_pagamentos/'+ this.forma_pagamento.id, this.forma_pagamento)
       .then(({ data }) => {   
        this.$emit('atualizarFormas')
      })
      .then(()=>{
          Swal.fire(
            'Atualização!',
            'Registro foi Atualizado.',
            'success'
          )
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });




    }else{


      axios.post(process.env.VUE_APP_API_URL + '/forma_pagamentos', this.forma_pagamento)
       .then(({ data }) => {   
        this.$emit('atualizarFormas')
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


    }

		


    }
  },


  components: {
   
  },




  setup() {
   
  
    return {
      
    
    };
  },
});
